import React, { useState, useEffect } from 'react';
import './HorizontalList.css';

function HorizontalList() {
  const [items, setItems] = useState(["horizontal 1", "horizontal 2"]); // Replace with your actual data

  useEffect(() => {
    // You can add your API calls or other initializations here
    // For example, fetch data from your server and update the state with setItems
  }, []);

  return (
    <div className="HorizontalList">
      <div className="HorizontalList-container">
        {items.map((item, index) => (
          <div key={index} className="HorizontalList-item">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

export default HorizontalList;
