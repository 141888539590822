import React, { useState, useEffect } from 'react';
import './VerticalList.css';

function VerticalList() {
  const [items, setItems] = useState(["vertical 1", "vertical 2"]); // Replace with your actual data

  useEffect(() => {
    // You can add your API calls or other initializations here
    // For example, fetch data from your server and update the state with setItems
  }, []);

  return (
    <div className="VerticalList">
      <div className="VerticalList-container">
        {items.map((item, index) => (
          <div key={index} className="VerticalList-item">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

export default VerticalList;
