import React, { useState, useEffect } from 'react';
import HorizontalList from './components/HorizontalList';
import VerticalList from './components/VerticalList';
import './App.css';

function App() {
  // Initialize state variables here using useState
  // const [stateVariable, setStateVariable] = useState(initialValue);

  useEffect(() => {
    // This is equivalent to componentDidMount in class components
    // You can add your API calls or other initializations here

    // Optionally, you can return a function for cleanup which acts like componentWillUnmount
    return () => {
      // Cleanup code if needed
    };
  }, []); // The empty array ensures this runs only once, similar to componentDidMount

  return (
    <div className="App">
      <div className="App-body">
        <HorizontalList />
        <VerticalList />
      </div>
    </div>
  );
}

export default App;
